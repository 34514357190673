.form-container {
    background-color: #f8f8f8;
    padding: 2rem;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .form-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .form-element {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .form-element label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .form-element select,
  .form-element input {
    padding: 0.5rem;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  
  .half-width-button {
    width: 48%;
    padding: 0.5rem;
    font-weight: bold;
    cursor: pointer;
  }
  
  .communities-container {
    display: flex;
    flex-direction: column;
  }
  
  .community-select {
    width: 100%;
  }
  
  .select {
    width: 100%;
  }
  