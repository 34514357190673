@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
  --primary-color: #1f3a4c;
  --secondary-color: #FFFFFF;
  --background-color: #F5F8FA;
  --text-color: #14171A;
  --border-color: #E0E0E0;
  --accent-color: #3d708f;
  --dark-primary-color: #1f3a4c;
  --dark-secondary-color: #3A3A3A;
  --dark-background-color: #1F1F1F;
  --dark-text-color: #E0E0E0;
  --dark-border-color: #444;
  --dark-accent-color: #4a9ac9;
  --button-hover-color: #030303;
  --dark-button-hover-color: #1a2c3a;
}

/* Dark theme styles */
body.dark-theme {
  color: var(--dark-text-color);
  background-color: var(--dark-background-color);
}

.dark-theme .navbar {
  background-color: var(--dark-primary-color);
}

.dark-theme .navbar a {
  color: var(--dark-secondary-color);
}

.dark-theme .form-container {
  background-color: var(--dark-secondary-color);
  /* updated color */
  border-color: var(--dark-border-color);
}

.dark-theme .form-title {
  color: var(--dark-accent-color);
}

.dark-theme .modal-content {
  background-color: var(--dark-background-color);
  color: var(--dark-text-color);
}

.dark-theme .modal-header {
  border-bottom-color: var(--dark-border-color);
}

/* Style for buttons container */
.job-posting-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

/* Style for individual buttons */
.job-posting-buttons button {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.job-posting-buttons button:hover {
  background-color: var(--button-hover-color);
}


.dark-theme .form-element label {
  color: var(--dark-accent-color);
}

.dark-theme .input,
.dark-theme .select,
.dark-theme input[type="text"] {
  background-color: var(--dark-background-color);
  border-color: var(--dark-border-color);
  color: var(--dark-text-color);
}

.disabled {
  pointer-events: none;
  opacity: 0.4; 
}

.dark-theme .input:focus,
.dark-theme .select:focus,
.dark-theme input[type="text"]:focus {
  color: var(--dark-text-color);
}

.dark-mode-text {
  color: #dddddd;

}


.dark-theme .half-width-button {
  background-color: var(--dark-accent-color);
  color: var(--dark-secondary-color);
  border: 1px solid var(--dark-accent-color);
}

.dark-theme .half-width-button:hover {
  background-color: var(--dark-button-hover-color);
  border-color: #7c8a99;
}

.dark-theme button {
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0.1);
}

.dark-theme button:hover {
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.2);
}

.dark-theme .half-width-button {
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0.1);
}

.dark-theme .half-width-button:hover {
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.2);
}

/* Fix for the text visibility in dark mode */
.dark-theme .auth-link {
  color: var(--dark-text-color);
}

/* Ensure the background of the logout link matches the navbar in light mode */
.auth-link {
  background-color: var(--primary-color);
  padding: 0.5rem 1rem;
}

/* Adjustments for the space between user info and theme switch icon */
.user-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
  /* space between user section and theme switch icon */
}

.theme-icon {
  margin-left: 1rem;
  /* space after the theme switch icon */
}


.dark-theme button {
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0.1);
}

.dark-theme button:hover {
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.2);
}

.dark-theme .half-width-button {
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0.1);
}

.dark-theme .half-width-button:hover {
  box-shadow: 0 2px 6px rgba(255, 255, 255, 0.2);
}

body {
  font-family: 'Roboto', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  width: 100vw;
  height: 850px;
  /* Setting a default height */
  overflow: hidden;
}

.home-image {
  width: 100%;
  height: 100%;
  /* Added height: 100vh */
  object-fit: cover;
  object-position: center;
  /* Added object-position: center */
}

/* Light theme styles */
body.light-theme .home-image {
  filter: brightness(100%);
}

/* Dark theme styles */
body.dark-theme .home-image {
  filter: brightness(50%);
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  flex-grow: 1;
  padding: 20px;

}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-middle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navbar {
  background-color: var(--primary-color);
  padding: 20px;
}


.navbar a {
  color: var(--secondary-color);
  text-decoration: none;
  margin-right: 20px;
}

.nav-icons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-icons-container>a {
  flex-grow: 1;
  text-align: center;
}

.nav-text,
.auth-link {
  color: #FFFFFF !important;
  text-decoration: none !important; /* This will remove the underline */
}

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-start,
.nav-middle,
.nav-end {
  display: flex;
}




.dark-theme .nav-icon {
  color: var(--dark-secondary-color);
}

/* Common styles for all nav icons */
.nav-icon {
  margin-right: 8px;
  vertical-align: middle;
}

/* Style adjustments for the menu icon */
.me-auto .nav-icon {
  margin-right: 16px;
}

/* Style adjustments for the home icon */
.nav-middle .nav-icon {
  margin-right: 8px;
}

/* Style adjustments for the theme toggle icon */
.justify-content-end .nav-icon {
  margin-right: 0;
}

/* Space out the nav sections */
.nav-me-auto,
.nav-middle,
.nav-justify-content-end {
  margin-left: 1rem;
  margin-right: 1rem;
}


.dark-theme .nav-icon {
  color: var(--dark-text-color);
}


.navbar a:hover {
  text-decoration: underline;
}

.form-container {
  max-width: 600px;
  margin: auto;
  background-color: var(--secondary-color);
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-height: calc(80vh - 100px);
  overflow-y: auto;
  /* Hiding scrollbar for various browsers */
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 10;
    /* For Chrome, Safari, and Opera */
  }

}

.form-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: var(--accent-color);
}

/* Loading bar styles for Light Theme */
.loading-bar-container {
  width: 100%;
  height: 20px;
  background-color: var(--border-color);
  margin: 10px 0;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.loading-bar {
  height: 100%;
  background-color: var(--accent-color);
  transition: width 0.2s;
}

.loading-bar-container span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--text-color);
}

/* Loading bar styles for Dark Theme */
body.dark-theme .loading-bar-container {
  background-color: var(--dark-border-color);
}

body.dark-theme .loading-bar {
  background-color: var(--dark-accent-color);
}

body.dark-theme .loading-bar-container span {
  color: var(--dark-text-color);
}



.form-element {
  padding: 0.5px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  /* Use relative units */
  flex-shrink: 10;
  /* Allows the form element to shrink */
}

.form-element label {
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: var(--accent-color);
}

.communities-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.community-select {
  flex: 1;
}

.input,
.select,
input[type="text"] {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: var(--background-color);
  font-size: 14px;
  max-width: 100%;
  box-sizing: border-box;
}

.input::placeholder,
.select::placeholder,
input[type="text"]::placeholder {
  color: var(--accent-color);
  opacity: 0.6;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  /* gap between email and logout link */
}

.logout-link {
  margin-top: 10px;
  /* space above the logout link */
}

button {
  cursor: pointer;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.button-container {
  display: inline-block;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin-left: 5%;
}

.half-width-button {
  flex: 0 0 calc(50%);
  background-color: var(--primary-color);
  /* Use primary color for the button background in light mode */
  color: var(--secondary-color);
  margin: 0 5px;
  cursor: pointer;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 20px;
  width: 200%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.half-width-button:last-child {
  margin-right: 0;
}

.half-width-button:hover {
  background-color: var(--button-hover-color);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

button:focus {
  outline: none;
}


/* Add media queries for mobile optimization */
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }

  @media screen and (max-width: 768px) {
    .image-container {
      height: 400px;
      /* Reducing height for mobile views */
    }
  }

  .navbar a {
    font-size: 14px;
    margin-right: 10px;
  }



  .form-element {
    margin-bottom: 10px;
  }

  .form-element label {
    margin-bottom: 2px;
  }

  .input,
  .select,
  input[type="text"] {
    padding: 8px;
  }

  .communities-container {
    flex-direction: column;
  }

  .community-select {
    flex: 0 0 100%;
    margin-bottom: 10px;
  }

  .button-container {
    flex-direction: column;
    margin-left: 0;
  }

  .half-width-button {
    flex: 0 0 calc(50%);
    background-color: var(--primary-color);
    /* Use primary color for the button background in both modes */
    color: var(--secondary-color);
    margin: 0 5px;
    cursor: pointer;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
    width: 200%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
  }


  .half-width-button:last-child {
    margin-right: 0;
  }
}

.footer {
  width: 100%;
  background-color: #1f3a4c;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  text-align: center;
  font-size: 14px;
  color: var(--secondary-color);
}


.input::placeholder,
.select::placeholder,
input[type="text"]::placeholder {
  font-family: 'Roboto', sans-serif;
}

.checkboxes-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  margin: 0 auto;
}

.checkbox-container {
  display: column;
  align-items: center;
}