body {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f4f4;
    color: #333;
}

.header {
    background-color: #003366;
    color: white;
    padding: 1rem 0;
    text-align: center;
    position: relative;
}

.logo {
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    height: 60px;
}

.search-container {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}

.search-container input {
    width: 100%;
    max-width: 800px;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 1rem;
    outline: none;
    margin: 0 10px;
}

.job-listings-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* Creates a responsive grid layout */
    gap: 1rem;
    /* Reduces gap between cards */
    padding: 1rem;
    /* Reduces overall padding */
    max-width: 1200px;
    margin: 0 auto;
    /* Centers the container */
}

.job-listing {
    background: white;
    padding: 1rem;
    /* Reduces padding within cards */
    border-radius: 8px;
    /* Adjust border radius for a subtler effect */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtler shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.job-listing:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.job-listing h2 {
    color: #003366;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.job-listing h3 {
    font-size: 1.2rem;
    margin-top: 0;
}

.job-listing p {
    line-height: 1.6;
    color: #666;
    margin-bottom: 1rem;
    text-align: center;
}

.job-listing a {
    display: block;
    text-align: center;
    padding: 0.75rem 1.5rem;
    background-color: #003366;
    color: white;
    border-radius: 20px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin-top: auto;
    /* Pushes the button to the bottom of the container */
}

.job-listing a:hover {
    background-color: #002244;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    /* Adjust margin-top as needed */
    flex-wrap: wrap;
    /* Allows pagination buttons to wrap */
}


.pagination button {
    background-color: #003366;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 5px;
    /* Reduce margin between buttons */
    min-width: 40px;
}



.pagination button:hover,
.pagination button.active {
    background-color: #002244;
}

/* Custom styles for react-select */
.search-select {
    width: 80%;
    /* Adjust as needed for your design */
    max-width: 1200px;
    /* Or 100% to fill the container */
    font-size: 1rem;
    margin: 0 auto;
    /* Centers the select box */
}

.total-jobs-text {
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
    /* Reduced padding */
    font-size: 1em;
    /* Adjusted for optimal size */
    color: #333;
    /* Good contrast color */
    margin: 2px 0 4px;
    /* Reduced top and bottom margin */
}

.search-container {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    /* Increase top padding to make space for the icon */
    padding-bottom: 2rem;
    /* Existing bottom padding */
}

/* Style for the search icon to position it above the search box */
.search-container:before {
    content: '\1F50D';
    /* Unicode for magnifying glass icon */
    font-size: 1.5rem;
    position: absolute;
    left: 50%;
    top: 2rem;
    /* Position at the top with proper spacing */
    transform: translate(-50%, -50%);
    color: #ccc;
    pointer-events: none;
    /* Makes the icon non-interactive */
}

/* Ensure the select dropdown text does not overlap with the icon */
.search-select__control {
    padding-left: 2rem;
    /* Adjust padding to ensure text starts after the icon */
}

/* Placeholder style */
::placeholder {
    color: #aaa;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #aaa;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .job-listings-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .job-listings-container {
        grid-template-columns: 1fr;
    }

    .logo {
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

/* Placeholder style */
::placeholder {
    color: #aaa;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #aaa;
}