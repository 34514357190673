.tala-landing-page-2 {
  font-family: 'Poppins', sans-serif;
  background-color: #f4f4f7; /* Light grey background for contrast */
  color: #333;
  display: flex;
  flex-direction: column;
  margin: 0 auto; /* Center the content */
  max-width: 1200px; /* Max width to align with the TALA site's content width */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  padding: 20px; /* Padding around the content */
  border-radius: 8px; /* Slight rounding of corners */
}

.job-details-modal .modal-content {
  width: 80%;
  height: 90%;
  margin: auto;
  background-color: #fff;
  overflow-y: auto; /* Enable scrolling within the modal */
  padding: 20px;
  border-radius: 10px; /* Optional: for rounded corners */
}

/* Overlay style */
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5);
}


/* Header Style */
.header-2 {
  background: #0051ba; /* Matching TALA's header color */
  color: white;
  padding: 10px 0;
  text-align: center;
  font-size: 1.5rem; /* Larger font size for header text */
}

.header-with-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.header-with-image img {
  width: 100%;
  max-height: 800px; /* Adjust the height as needed */
  object-fit: cover;
}

.header-text {
  text-align: center;
  padding: 20px;
}

.header-text p {
  margin-bottom: 10px;
}

/* Search Container */
.search-container-2 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  position: relative;
  flex-wrap: wrap;
  gap: 10px; /* Add some space between input fields */
}


/* Adjust job summary size within the grid */
.job-summaries-full-width .job-summary {

  width: 85%; /* Full width of the grid column */
}

.job-summaries-full-width {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)); /* Two columns on desktop */
  gap: 1rem;
  padding: 1rem;   
}



.search-container-2 input, .search-input, .mos-code-input {
  max-width: 1160px; 
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-size: 1rem;
  outline: none;
  background-color: #eef2f7;
  width: auto; 
  margin-top: 10px;
}

.search-input, .mos-code-input {
  width: auto; 
  max-width: 580px; 
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-size: 1rem;
  outline: none;
  background-color: #eef2f7;
}


/* Job Counter */
.job-counter {
  text-align: center;
  font-weight: normal; 
  padding: 10px 0;
  font-size: 1.2rem;
  color: white; 
  margin: 10px 0; 
}



.content {
  display: flex;
  flex: 1; 
}

/* Job Summaries */
.job-summaries {
  flex: 0 0 35%;
  margin-right: 1rem;
  overflow-y: auto; /* This allows the job summaries to scroll independently if needed */
}

.job-summary {
  cursor: pointer;
  margin-bottom: 1rem; /* Space between cards */
  border-bottom: 2px solid #ccc; /* Make the demarcation line bolder */
  background-color: #fff; /* Different color from container for contrast */
  border-radius: 8px; /* Rounded corners for aesthetics */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: box-shadow 0.3s ease;
  margin: 1rem auto; /* Vertical margin for spacing, auto for horizontal centering */
  padding: 1rem; 
}
.job-summary:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Shadow effect on hover for interactivity */
}

.job-summary.selected {
  background-color: rgba(214,0,0,0.1);
}

.job-category {
  font-weight: bold;
  color: rgba(214,0,0,0.94);
}

.search-filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #030063;
  border: 1px solid #ddd; /* Border to match the job summary container */
  border-radius: 4px; /* Slight rounding of corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow to lift the container */
  margin-bottom: 20px;
}



/* Job Summaries and Job Detail Panel */
.job-summaries, .job-description-panel {
  /* Adding padding and margin for better spacing and readability */
  padding: 20px;
  margin: 10px;
  background: #ffffff; /* White background for content areas */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 4px; /* Slight rounding of corners */
}

.job-description-panel {
  flex: 0 0 55%;
}


/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}
/* Modal Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Modal Content Styles Adjusted for Close Button */
.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  margin: auto;
  left: 50%; /* Position the modal in the center */
  transform: translate(-50%, -50%); 
  position: fixed; /* Fixed position relative to the viewport */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%; /* Set the desired width */
  max-width: 600px; 
}

.pagination button, .apply-button, .share-button {
  background-color: #0051ba; /* TALA's primary color */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 5px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.pagination button.active {
  background-color: #ffc220; /* TALA's accent color */
  color: #333;
}

/* Filter Container */
.filter-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;
}

.filter-container select {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Additional CSS for MOS Code display */
.mos-code, .mos-code-detail {
  font-size: 0.8rem; /* Smaller font size for MOS Code */
  color: #555; /* Muted color for unobtrusive display */
  margin-bottom: 0.5rem; /* Spacing below the MOS Code */
}

.job-summary .mos-code {
  margin-top: -0.5rem; /* Adjust spacing if necessary */
}

.job-detail .mos-code-detail {
  font-weight: bold; /* Optional: make the MOS Code stand out */
  line-height: 1.6; /* Increase line spacing for better readability */
  font-size: 1rem; /* Slightly larger font size */
  max-width: 800px; /* Set a max-width for the job details for better reading experience */
  margin: auto; /* Center the job detail content */
}

.job-detail {
  font-family: 'Helvetica Neue', Arial, sans-serif; /* Professional font */
  font-size: 16px; /* Standard readable size */
  line-height: 1.6; /* Increased line spacing for readability */
  color: #333; /* Soft black for text */
  background: #fff; /* White background */
  padding: 20px; /* Padding inside the modal */
  border-radius: 4px; /* Slightly rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 600px; /* Maximum width of the modal */
  margin: auto; /* Center the modal in the page */
}

.job-detail h2 {
  font-size: 22px; /* Slightly larger size for headings */
  margin-bottom: 10px; /* Space below the heading */
}

.job-detail p {
  margin-bottom: 10px; /* Space between paragraphs */
}

.job-detail .apply-button .share-button{
  font-size: 18px; /* Larger font size for important buttons */
  padding: 10px 15px; /* Larger clickable area for the button */
  background-color: #0051ba; /* Use the primary TALA color */
  border: none; /* No border for a cleaner button */
  border-radius: 4px; /* Slightly rounded corners for the button */
  color: white; /* White text for contrast */
  text-transform: uppercase; /* Uppercase text for the button for emphasis */
  font-weight: bold; /* Bold font weight for the button text */
  text-align: center; /* Center the text within the button */
  display: block; /* Block display to occupy its own line */
  width: fit-content; /* Fit the content of the button */
  margin: 20px auto 0; /* Center the button and add space above */
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Remove underline from the link */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for depth */
}

.apply-button {
  text-decoration: none; /* Removes underline */
}

.job-detail .apply-button:hover .share-button:hover {
  background-color:#030063; /* Darker color on hover for interaction feedback */

}

.job-detail .read-more {
  color: #0051ba; /* Use the primary TALA color */
  font-weight: bold; /* Bold font for emphasis */
  cursor: pointer; /* Pointer cursor on hover */
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .job-detail {
    padding: 15px; /* Slightly less padding on smaller screens */
    font-size: 12px; /* Slightly smaller font size on smaller screens */
  }

  .search-container-2 {
    flex-direction: column;
    align-items: stretch; /* This will stretch the children to the full width of the container */
  }

  .search-container-2 .search-input,
  .search-container-2 .mos-code-input {
    width: 100%; /* Full width */
    max-width: none; /* Override any max-width set */
    margin-bottom: 10px; /* Add some space between the inputs */
  }

  .search-container-2 {
    flex-direction: column; /* Stack the children vertically */
    align-items: center; /* Center the children horizontally */
  }

  .search-container-2 input,
  .search-input,
  .mos-code-input {
    width: 90%; /* Set the width to take up most of the container */
    margin-bottom: 10px; /* Add space between the input fields */
  }

  .job-detail h2 {
    font-size: 20px; /* Slightly smaller size for headings on smaller screens */
  }

  .job-detail .apply-button {
    font-size: 16px; /* Smaller font size for the button on smaller screens */
  }

  .job-summaries,
  .job-description-panel {
    margin-right: 0;
    max-height: 70%;
  }

  .modal-content {
    width: 90%; /* Full width on smaller screens with some padding */
  }

  .search-container-2 input, .search-input, .mos-code-input {
    width:200px; /* Adjust to full width on small screens, maintaining the same padding */
  }

  .search-filter-container {
    flex-direction: column;
    width: 100%;
  }
  .filter-container select {
    width: 90%;
  }
  .job-summaries-full-width {
    grid-template-columns: 1fr; /* Single column */
  }
}