/* JobPostingPage.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {
    --tala-primary-color: #030063; /* TALA's primary blue */
    --tala-accent-color:#00B0FF  ; /* TALA's accent gold */
    --tala-background-color: #f4f4f7; /* Light grey background */
    --tala-text-color: #333; /* Dark grey text for contrast */
    --tala-border-color: #ccc; /* Border color */
    --tala-hover-color: #00397a; /* Darker blue on hover */
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: var(--tala-background-color);
    color: var(--tala-text-color);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.job-posting-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    line-height: 1.6;
}

/* Hide the back button on screens wider than 768px */
.back-button {
    display: none;
  }

.job-posting-page h1,
.job-posting-page h2,
.job-posting-page h3,
.job-posting-page p,
.job-posting-page ul,
.job-posting-page li {
    margin-bottom: 0.75rem; /* Adds a bottom margin to each element */
}

.job-posting-page h1 {
    margin-top: 3rem;
}
.job-posting-buttons {
    display: flex;
    gap: 10px;
    text-decoration: none; /* Removes underline */
    margin-right: 80px;
}

.job-posting-buttons button {
    background-color: rgba(214,0,0,0.94); 
    color:white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    text-decoration: none; /* Removes underline */
}

.job-posting-buttons button:hover {
    background-color: var(--tala-hover-color);
    text-decoration: none; /* Removes underline */
}

h1, h2 {
    color: var(--tala-primary-color);
}

h1 {
    margin-top: 0;
}

.job-details {
    text-align: left;
    width: 100%;
}

.job-description {
    border-top: 2px solid var(--tala-border-color);
    margin-top: 20px;
    padding-top: 20px;
}

.job-posting-footer {
    width: 100%;
    padding: 20px;
    background-color: rgba(214,0,0,0.94);
    color: white;
    text-align: center;
    border-radius: 0 0 8px 8px;
    margin-top: 100px;
}


@media screen and (max-width: 768px) {
    .job-posting-page {
        margin: 10px;
        padding: 15px;
    }

    .job-posting-buttons button {
        padding: 8px 15px;
        text-decoration: none; /* Removes underline */
        margin-right: 80px;
    }

    .job-details, .job-description {
        padding: 10px;
    }
    .back-button {
        display: inline-block; /* Or 'block', depending on your layout */
      }
}

/* Additional styles to enhance visual appeal */
.job-description p {
    line-height: 1.6;
}

/* Enhancements for links and buttons */
a, button {
    transition: all 0.3s ease;
}

a:hover, button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.apply-button, .share-button {
    background-color: rgba(214,0,0,0.94); /* Original accent color */
    color: white;
    transition: all 0.3s ease; /* Smooth transition for all properties */
}

.apply-button:hover, .share-button:hover {
    background-color: rgba(214,0,0,1); /* Slightly darker shade on hover */
    transform: scale(1.05); /* Slight increase in size */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Soft shadow for depth */
}


/* Improvements for readability and spacing */
p, li {
    margin-bottom: 10px;
}
